import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { axiosInstance, axiosScraperInstance } from "../axiosInterceptor";
import { getAccessToken } from "../utils";

type fetcherType = {
  key: string;
  data: any;
};

export const fetcher: (data: any) => any = (props: fetcherType) => {
  const { key, data } = props;
  return axiosInstance
    .post<any>(`${key}`, data, {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    })
    .then((r: any) => r.data);
};

export const downloadZip = (productId: number) => {
  return axiosInstance
    .get<any>(`/product/zip/` + productId, {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
      responseType: "blob",
    })
    .then((r: any) => r.data);
};
export const downloadCSVFile = async (productId: number) => {
  try {
    const response = await axiosInstance.get(
      `/product/downloadCSV/${productId}`,
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
        responseType: "arraybuffer",
      }
    );

    return response.data;
  } catch (error) {
    toast.error("File not Found");
    throw error;
  }
};

export const directMail = (cert: any) => {
  return axiosScraperInstance
    .post<any>(`/scrapers/material/certificate-expire-mail`, cert, {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    })
    .then((r: any) => r.data);
};

export const sendRohsMail = (data: any) => {
  return axiosScraperInstance
    .post<any>(`/scrapers/product/rohs-send-mail`, data)
    .then((r: any) => r.data);
};

export const flagMailNotification = (data: any) => {
  return axiosScraperInstance
    .post<any>(`/scrapers/product/flag-mail`, data)
    .then((r: any) => r.data);
};

export const emailToManufacturer = async (body: any) => {
  return await axiosScraperInstance
    .post<any>("/scrapers/email-manufacturer", body)
    .then((r: any) => r.data);
};
export const productComponentNotification = async (body: any) => {
  try {
    console.log(body, "inside fetchers add product email");
    return await axiosScraperInstance
      .post<any>("/scrapers/item-added-notification", body)
      .then((r: any) => r.data);
  } catch (error) {
    console.log(error, "error inside in fetcher");
  }
};

export const productReviewNotification = async (body: any) => {
  try {
    return await axiosScraperInstance
      .post<any>("/scrapers/product-review-notification", body)
      .then((r: any) => r.data);
  } catch (error) {
    console.log(error, "error inside in fetcher ");
  }
};

export const vdeChecker = async (body: any) => {
  try {
    const response = await axiosScraperInstance.post<any>(
      "/scrapers/vde/expire-vde",
      body
    );
    return response.data;
  } catch (error) {
    // Handle any errors that occurred during the request
    console.error("Error in vdeChecker:", error);
    throw error; // Propagate the error to the caller
  }
};
// export const vdeSpecificCertificate = async (body: any) => {
//   try {
//     const response = await axiosScraperInstance.post<any>(
//       '/scrapers/vde/specific-certificate-vde',
//       body
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Error in vdeChecker:', error);
//     throw error;
//   }
// };
export const getRequiredCertificates: () => any = () => {
  return axiosInstance
    .get<any>(`required-certificate`, {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    })
    .then((r: any) => r.data);
};

export const getManufactureMails = async (body: any) => {
  try {
    const response = await axiosInstance.post<any>("/manufacture-mails", body);
    return response.data;
  } catch (error) {
    // Handle any errors that occurred during the request
    console.error("Error in Fetching Mail:", error);
    throw error; // Propagate the error to the caller
  }
};
export const manufacturerMails = async (body: any) => {
  try {
    const response = await axiosInstance.post<any>(
      "/manufacture-mails/get-mails",
      body
    );
    return response;
  } catch (error) {
    console.error("Error in Fetching Mails of all materials:", error);
  }
};

export const scrappingFetcher = (data: any) => {
  return axiosScraperInstance
    .post<any>(
      `/search`,
      { search: data },
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    )
    .then((r: any) => r.data);
};

export const deleteCertificatesFetcher = (data: any) => {
  return axiosInstance
    .post<any>(`/certificate/delete/by/materialIds`, data, {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    })
    .then((r: any) => r.data);
};

export const getTemporaryCertificatesByMaterial = async (
  materialId: number,
  body: any
) => {
  const response = await axiosInstance.post(
    "/material/temp-certificates/by/material/" + materialId,
    body,
    {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    }
  );
  return response.data;
};

export const manufacturerDocFetcher = (data: any) => {
  console.log("Api running ");

  return axiosInstance
    .post<any>(
      `/material/getDocsbyProductId`,
      { product_id: data },
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    )
    .then((r: any) => r.data);
};

export const findProductCertificateFetcher = (data: any) => {
  console.log("Api running ");

  return axiosInstance
    .post<any>(
      `/material/find-certificates`,
      { materials: data },
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    )
    .then((r: any) => r.data);
};
export const changeCdocStatus = (data: any) => {

  return axiosInstance
    .post<any>(
      `/cdocs/change-status`,
      { ids: data },
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    )
    .then((r: any) => r.data);
};
export const getProductCertificateFetcher = async (materialId: number) => {
  console.log("Api running ");

  const response = await axiosInstance.get(
    "/material/get/certificates/by/material/" + materialId,
    {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    }
  );
  console.log(response, "GET RESPONSE");
  return response.data;
};

export const getTemporaryCertificates = async (
  materialId: number,
  take?: number,
  skip?: number
) => {
  const response = await axiosInstance.get(
    "/material/temp-certificate/by/materialId/" +
      materialId +
      "/take/" +
      take +
      "/skip/" +
      skip,
    {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    }
  );

  return response.data;
};

export const getSpecificTemporaryCertificates = async (
  materialId: number,
  take?: number,
  skip?: number,
  manufacture?: string,
  modelName?: string
) => {
  console.log(
    "/material/temp-certificate/by/materialId/" +
      materialId +
      "/take/" +
      take +
      "/skip/" +
      skip +
      "/manufacture/" +
      manufacture +
      "/model/" +
      modelName,
    "pagination base link"
  );

  const response = await axiosInstance.get(
    "/material/temp-certificate/by/materialId/" +
      materialId +
      "/take/" +
      take +
      "/skip/" +
      skip +
      "/manufacture/" +
      manufacture +
      "/model/" +
      modelName,
    {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    }
  );
  return response.data;
};
export const getCompanies = async (userId?: any, roleId?: any) => {
  let response;
  if (userId && roleId) {
    const param = {
      userId: userId,
      roleId: roleId,
    };
    response = await axiosInstance.post(
      "/company/byUserRole",
      {},
      {
        params: {
          userId: userId,
          roleId: roleId,
        },
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    );
  } else {
    response = await axiosInstance.get("/company", {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    });
  }
  return response.data;
};

export const getUsers = async (companyId?: any) => {
  let response;
  if (companyId) {
    response = await axiosInstance.post(
      "/user/companyUser",
      {},
      {
        params: {
          companyId: companyId,
        },
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    );
  } else {
    response = await axiosInstance.get("/user/getAll", {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    });
  }
  return response.data;
};
export const registerUser = async (data: any) => {
  try {
    return await axiosInstance.post<any>("user/registration", data);
  } catch (error) {
    console.log(error.message, "user signup err");
  }
};

export const getMaterialStatus = async () => {
  try {
    return await axiosInstance.get("material-status");
  } catch (error) {
    console.log(error.message, "get material status error");
  }
};

export const updateMaterialStatus = async (materialId: number, body: any) => {
  try {
    return await axiosInstance.patch(
      "material/update-material-status/" + materialId,
      body,
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    );
  } catch (error) {
    console.log("material status update error: ", error);
  }
};
export const forgetPasswordMailApi = (data: any) => {
  return axiosScraperInstance.post<any>(`/scrapers/forget-password`, data);
  // .then((r: any) => r);
};

export const addManufactureAPI = (data: any) => {
  return axiosInstance
    .post<any>(
      `/manufacturer`,
      { name: data },
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    )
    .then((r: any) => r.data);
};

export const getManufactures = () => {
  return axiosInstance
    .get<any>(
      `/manufacturer`,

      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    )
    .then((r: any) => r.data);
};

export const getCertificatesByManufacture = (id: number) => {
  return axiosInstance
    .get<any>(
      `/manufacturer/` + id,

      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    )
    .then((r: any) => r.data);
};

export const getCertificatesByManufactureCdoc = (payload: any) => {
  return axiosInstance
    .post<any>(
      "/cdocs/get-component-docs",
      payload,

      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    )
    .then((r: any) => r.data);
};
export const getAllCertificates = (page: number, size: number, manufacturer?: string) => {
  return axiosInstance
    .post<any>(
      `/cdocs/get-all-cdocs?page=${page}&size=${size}&manufacturer=${manufacturer}`,
      {},
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    )
    .then((r: any) => r.data);
};

export const getManufacturerByID = (payload: any) => {
  return axiosInstance
    .post<any>("/manufacturer/scrap", payload, {
      headers: {
        Authorization: `${getAccessToken()}`,
      },
    })
    .then((r: any) => r.data);
};
