import _ from "lodash";
import React, { useEffect, useState } from "react";
import { mutate } from "swr";
import { materialAPI, productApi } from "../../api/apiRequests";
import useUser from "../../api/SWR/useUser";

import {
  CERTIFICATE_COLLECTION_STATUSES,
  COMPONENT_STATUSES,
  ROHS_COMPONENT_STATUSES,
  SEARCH_COMPONENTS
} from "../../constants/enums";
import { formateDate } from "../../helper/emailNotification";
import { Button } from "../button/Button";
import styles from "./styles.module.scss";
import CustomModal from "../custom-modal/CustomModal";
import { manufacturerMails, sendRohsMail } from "../../api/SWR/fetchers";
import { getAccessToken } from "../../api/utils";
import { toast } from "react-toastify";
import { receiverEmails } from "../../api/constants";

import { Input } from "../input/Input";
type ProductInfoPropsTypes = {
  id: number;
  title: string;
  description: string;
  markets?: any;
  type?: any;
  standards?: any;
  developmentStatus?: any;
  manufacturer?: string;
  customer?: any;
  status?: any;
  materials?: any;
  mutate?: any;
  productCreatedDate?: any;
  productApprovalDate: string;
  isRoHSAvailable: boolean;
  setCallbackState:any,
};

const ProductInfo = ({
  id,
  title,
  isRoHSAvailable,
  description,
  markets,
  type,
  standards,
  developmentStatus,
  manufacturer,
  customer,
  status,
  materials,
  productCreatedDate,
  mutate,
  productApprovalDate,
  setCallbackState,
}: ProductInfoPropsTypes) => {
  const { data } = useUser();
  const statsArr = [
    { title: "MANUFACTURER", value: manufacturer },
    {
      title: "PRODUCT TYPE",
      value: type.length
        ? type.map((item: any) => <span key={item.id}>{item.title} </span>)
        : "",
    },
    {
      title: "PRODUCT STANDARDS",
      value: standards.length
        ? _.join(
          standards.map((item: any) => `${item.title}`),
          ", "
        )
        : "",
    },
    {
      title: "MARKETS",
      value: markets.length
        ? markets.map((item: any) => <span key={item.id}>{item.title} </span>)
        : "",
    },
    { title: "DEVELOPMENT STATUS", value: developmentStatus?.title },
  ];
  const [productStatusId, setProductStatusId] = useState<number>(
    status?.id || CERTIFICATE_COLLECTION_STATUSES.PENDING
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rohsPopUp, setRohsPopUp] = useState<boolean>(false);
  const [roshLoading, setRoshLoading] = useState<boolean>(false);
  const [searchDD,setSearchDDValue ] = useState<any>(SEARCH_COMPONENTS.type);
  const [searchValue,setSearchValue]=useState<string>('') // Assuming you have COMPONENT_STATUSES imported from somewhere



  const [componentsProgressStatus, setComponentsProgressStatus] = useState(
    checkMaterialsProgress()
  );
  const [roHSProgressStatus, setRoHSProgressStatus] = useState<any>()
  // const [rohsComponentsProgressStatus, setRohsComponentsProgressStatus] = useState(
  //   checkMaterialsProgress()
  // );
  const user = useUser();
  const isAdmin = user.data.role === 1;
  const changeProductStatus = async () => {
    setIsLoading(true);
    if (productStatusId === CERTIFICATE_COLLECTION_STATUSES.PENDING) {
      // approved product -> ready for review
      await productApi.updateProductStatus(
        id,
        CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
      );
      setProductStatusId(CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW);
    } else {
      // in review product -> pending
      await productApi.updateProductStatus(
        id,
        CERTIFICATE_COLLECTION_STATUSES.PENDING
      );
      setProductStatusId(CERTIFICATE_COLLECTION_STATUSES.PENDING);
    }
    await mutate();
    setIsLoading(false);
  };

  const collectRoHSCertificates = async () => {
    console.log((isRoHSAvailable), (isAdmin), (user.data.isRoHSAllow));

    setRohsPopUp(true);
  };



  useEffect(() => {
    if (status) {
      setProductStatusId(status?.id);
    } else {
      setProductStatusId(CERTIFICATE_COLLECTION_STATUSES.PENDING);
    }
  }, [status]);

  useEffect(() => {
    getAllMaterialMailsAddresses();
  }, []);

  // this will return many mails addresses against  product materials names
  const getAllMaterialMailsAddresses = async () => {
    let materialsName = materials.map((material: any) => material.manufacturer);
    const payload = {
      names: materialsName,
    };
    let response = await manufacturerMails(payload);
    let mailArr = response?.data.map((mail: any) => mail.name)
    let materialsManufacturerName = materials.map((material: any) => material.manufacturer)
    let pendingManufacturerName = materialsManufacturerName.filter((matName: any) => !mailArr.includes(matName))
    let mailAddressManufacturerName = materialsManufacturerName.filter((matName: any) => mailArr.includes(matName))
    checkRoHSProgress(pendingManufacturerName, mailAddressManufacturerName)
  };


  function checkMaterialsProgress() {
    var totalMaterials = materials.length;

    // Certificate Status
    var approvedMaterials = materials.filter(
      (material: any) =>
        material?.materialStatus?.id === COMPONENT_STATUSES.APPROVED
    );
    var notApprovedMaterials = materials.filter(
      (material: any) =>
        material?.materialStatus?.id === COMPONENT_STATUSES.NOT_APPROVED
    );

    var waitingForReply = materials.filter(
      (material: any) =>
        material?.materialStatus?.id === COMPONENT_STATUSES.WAITING_FOR_REPLY
    );
    var inReviewMaterials = materials.filter(
      (material: any) =>
        material?.materialStatus?.id === COMPONENT_STATUSES.IN_REVIEW ||
        material.materialStatus == null
    );
    var approvedMaterialAvg = Math.floor(
      (approvedMaterials.length / totalMaterials) * 100
    );
    var notApprovedMaterialAvg = Math.floor(
      (notApprovedMaterials.length / totalMaterials) * 100
    );
    var waitingForReplyAvg = Math.floor(
      (waitingForReply.length / totalMaterials) * 100
    );
    var inReviewMaterialsAvg = Math.floor(
      (inReviewMaterials.length / totalMaterials) * 100
    );
    let componentAverage = {
      approvedMaterials: {
        total: approvedMaterials.length,
        avg: approvedMaterialAvg + "%",
      },
      notApprovedMaterials: {
        total: notApprovedMaterials.length,
        avg: notApprovedMaterialAvg + "%",
      },
      waitingForReply: {
        total: waitingForReply.length,
        avg: waitingForReplyAvg + "%",
      },
      inReviewMaterials: {
        total: inReviewMaterials.length,
        avg: inReviewMaterialsAvg + "%",
      },

    };
    return componentAverage;
  }

  // RoHS
  function checkRoHSProgress(pendingManufacturerName = null, mailAddressManufacturerName: any) {

    var totalMaterials = materials.length;
    var rohsApprovedMaterials = materials.filter(
      (material: any) =>
        material?.rohsStatus == ROHS_COMPONENT_STATUSES.APPROVED
    );

    var rohsWaitingForReply = materials.filter(
      (material: any) =>
        material?.rohsStatus == ROHS_COMPONENT_STATUSES.WAITING_FOR_REPLY
    );
    var rohsInReviewMaterials = materials.filter(
      (material: any) =>
        material?.rohsStatus != ROHS_COMPONENT_STATUSES.WAITING_FOR_REPLY &&
        material?.rohsStatus != ROHS_COMPONENT_STATUSES.APPROVED &&
        mailAddressManufacturerName.includes(material?.manufacturer)
    );


    // RoHS Status

    // rohsStatus when emails sent and updated material table 
    var rohsPendingMaterials = materials.filter((material: any) =>
      material?.rohsStatus == ROHS_COMPONENT_STATUSES.PENDING
    )
    if (rohsPendingMaterials.length < 1) {

      rohsPendingMaterials = pendingManufacturerName

    }
    var rohsApprovedMaterialAvg = Math.floor(
      (rohsApprovedMaterials.length / totalMaterials) * 100
    );
    var rohsWaitingForReplyAvg = Math.floor(
      (rohsWaitingForReply.length / totalMaterials) * 100
    );
    var rohsInReviewMaterialsAvg = Math.floor(
      (rohsInReviewMaterials.length / totalMaterials) * 100
    );
    var rohsPendingMaterials1Avg = Math.floor((rohsPendingMaterials.length / totalMaterials) * 100);

    let roHSComponentAvg = {
      rohsApprovedMaterials: {
        total: rohsApprovedMaterials.length,
        avg: rohsApprovedMaterialAvg + "%",
      },
      rohsWaitingForReply: {
        total: rohsWaitingForReply.length,
        avg: rohsWaitingForReplyAvg + "%",
      },
      rohsInReviewMaterials: {
        total: rohsInReviewMaterials.length,
        avg: rohsInReviewMaterialsAvg + "%",
      },
      rohsPendingMaterial: {
        total: rohsPendingMaterials.length,
        avg: rohsPendingMaterials1Avg + "%"
      }
    }

    setRoHSProgressStatus(roHSComponentAvg)
  }
  useEffect(() => {
    setComponentsProgressStatus(checkMaterialsProgress());
  }, [materials]);



  // const sendRoHSMail = async () => {
  //   let customRoHSText = `Dear Sir/Madam, <br/>
  //   We are requesting RoHS declaration on behalf of ${data?.firstName} ${data?.lastName}, at ${
  //         data?.company?.name ? data?.company?.name : "Company"
  //       }, ${
  //         data?.company?.name ? data?.company?.name : "Company"} are using or intends to use your products  manufactured by myManufacturer. <br/>
  //         We are requesting RoHS declaration(s) for the following products: <br/>
  //         myComponent <br/>
  //         If we are reaching out to an incorrect email address for this purpose, please reply to us or forward this to the proper recipient. <br/>
  //         Thank you for your attention to this matter. <br/>
  //         Best Regards,
  //   `;
  //   try {
  //     setRoshLoading(true);
  //     let res = await sendRohsMail({
  //       "id":id,
  //       "token":getAccessToken(),
  //       "mailInfo":{
  //         "ccMails":receiverEmails.ccEmails,
  //         "htmlContent":customRoHSText
  //     }
  //     });

  //     if (res.result) {
  //       toast.success("Mails Sent Successfully!");
  //     }
  //     await mutate();
  //     setRoshLoading(false);
  //     setRohsPopUp(false);


  //   } catch (error) {      
  //     setRoshLoading(false);
  //     toast.error("Something Went Wrong!");
  //   }

  // }
  const submittSearch=()=>{
    if(searchValue.length==0){
      // alert("Write something to search")
      toast.error("Write Something to Search");
      return;
    }
    const regex = /[.,\/#%*+\s-]/g;
    const query=searchValue.replace(regex,"");
    const tempMap={
      "type":searchDD,
      "query":query.trim().toLowerCase()
    };
    setCallbackState(tempMap);
   
  }
 const searchHandler=(e:any)=>{
   setSearchValue(e.target.value);
   if(!e.target.value){
     console.log("-----====////////>",e.target.value)
    
    setCallbackState({})
   }
   
 }
  const handleSelectChange = (e:any) => {
    console.log("======> ",e.target.value)
    setSearchDDValue(e.target.value);
   

  };


  const SelectComponent = ( {value, onChange} :any) => {
    return (
      <td className="whitespace-nowrap align-top text-sm text-gray-500">{
      <select
      className="component-select rounded py-1.5 border border-gray-200 w-24"
        // className={styles.componentSelect}
        onChange={onChange}
        value={value}
        name="componentStatus"
        id="component-status"
      >
      
        <option value={SEARCH_COMPONENTS.type}>TYPE</option>
        <option value={SEARCH_COMPONENTS.internalReference}>INTERNAL COMPONENT REFERENCE</option>
        <option value={SEARCH_COMPONENTS.componentName}>COMPONENT NAME</option>
        <option value={SEARCH_COMPONENTS.manufacturer}>MANUFACTURER</option>
      </select>
  }
   </td>
    );
  };
  
  return (
    <div className={styles.wrapper}>
      <div className="flex justify-between ">
        {/* title, status, status button */}
        <div className="flex flex-1 justify-between items-start">
          <div className="flex flex-col">
            <h1 className="text-4xl leading-10 font-medium mb-2.5 text-primary-500  max-w-[70] w-70 ">
              {title}
            </h1>
            <p className={styles.desc}>{description}</p>
          </div>
          {/* <div 
          className={"flex flex-wrap gap-2"}>
        {  SelectComponent({ value:searchDD ,onChange:handleSelectChange})}

            <Input
              style={{ width: "300px" }}
              value={searchValue}
              
              onChange={searchHandler}
              placeholder="Search"
              inputValue={searchValue}

              handleClearInput={()=>{
                setCallbackState({});
                setSearchValue("");}}
              ></Input>

            <Button
              label=
              "Search"

              className={"mr-2.5"}
              onClick={ submittSearch}
            />
          </div> */}
          {/* {isAdmin && productStatusId && (
            <Button
              label={`${
                productStatusId === CERTIFICATE_COLLECTION_STATUSES.PENDING
                  ? "Approve certificate list"
                  : "Set status to In Review"
              }`}
              onClick={changeProductStatus}
              loading={isLoading}
            />
          )} */}
          {/* critical component, certificate list status */}
          <div className="flex flex-1 items-center justify-end">
            <div className="w-72 flex items-center gap-2 flex-col">
              {/* status */}
              {
                <div className="flex flex-col ml-3">
                  <p className="text-gray-400">PRODUCT STATUS</p>
                  {/* <p
                className={`${
                  productStatusId === CERTIFICATE_COLLECTION_STATUSES.PENDING
                    ? "text-yellow-400"
                    : "text-primary_color"
                }  text-xl font-extrabold`}
              >
                {productStatusId === CERTIFICATE_COLLECTION_STATUSES.PENDING
                  ? "In Review"
                  : "Approved"}
              </p> */}
                  <p
                    className={`${productStatusId === CERTIFICATE_COLLECTION_STATUSES.PENDING
                      ? "text-yellow-400"
                      : productStatusId === CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
                        ? "text-blue-400" // Set the color for IN REVIEW state
                        : "text-primary_color"
                      }  text-xl font-extrabold`}
                  >
                    {productStatusId === CERTIFICATE_COLLECTION_STATUSES.PENDING
                      ? "Pending"
                      : productStatusId === CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
                        ? "In Review"
                        : "Approved"}
                  </p>

                  <p className="text-gray-400">
                    {productStatusId !==
                      CERTIFICATE_COLLECTION_STATUSES.PENDING && productStatusId !==
                      CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW &&
                      productApprovalDate &&
                      formateDate(productApprovalDate)}
                  </p>
                  {/* <Button
                className="mt-4 mb-4"
                disabled={
                  
                  !(( isRoHSAvailable) && ( isAdmin || user.data.isRoHSAllow ))
                }
                label={`${"Collect RoHS"}`}
                onClick={collectRoHSCertificates}
                loading={isLoading}
              /> */}

                </div>
              }

            </div>
          </div>

        </div>

      </div>
      {/*   // OCT-18 2023  ROHS code commented because client not want it right now */}
      {/* <div className="flex flex-1 items-center justify-end">
            <div className="w-72 flex items-center gap-2 flex-col">
              <p>RoHS list status</p>

           

              {
                <div className="flex gap-px w-full">
                  <div
                    className=" bg-green-400 rounded-full h-2"
                    style={{
                      width: roHSProgressStatus?.rohsApprovedMaterials.avg
                    }}
                  ></div>
                  <div
                    className=" bg-purple-400 rounded-full h-2"
                    style={{
                      width: roHSProgressStatus?.rohsWaitingForReply.avg,
                    }}
                  ></div>
                  <div
                    className=" bg-gray-400 rounded-full h-2"
                    style={{
                      width: roHSProgressStatus?.rohsInReviewMaterials.avg,
                    }}
                  ></div>
                  <div
                    className=" bg-yellow-400 rounded-full h-2"
                    style={{
                      width: roHSProgressStatus?.rohsPendingMaterial.avg,
                    }}
                  ></div>
                </div>
              }
          
              <div className="flex gap-1 text-gray-400">
                <p className="whitespace-nowrap">
                  Approved: {roHSProgressStatus?.rohsApprovedMaterials.total},{" "}
                </p>
                <p className="whitespace-nowrap">
                  Waiting for reply:{" "}
                  {roHSProgressStatus?.rohsWaitingForReply.total},
                </p>
                <p className="whitespace-nowrap">
                  In Review: {roHSProgressStatus?.rohsInReviewMaterials.total}
                </p>
                <p className="whitespace-nowrap">
                  Pending: {roHSProgressStatus?.rohsPendingMaterial.total}
                </p>
              </div>
            </div>
          </div> */}
      <div className="flex justify-between ">
        <ul className={styles.list}>
          {statsArr.map((item) => (
            <li key={item.title}>
              <h3>{item.title}</h3>
              <div>{item && item.value ? item.value : "N/A"}</div>
            </li>
          ))}
        </ul>
        <div className="text-center">
          <p className="mb-2">Certificate list status</p>

          {/* progress status */}

          {
            <div className="flex gap-px w-full">
              <div
                className=" bg-green-400 rounded-full h-2"
                style={{
                  width: componentsProgressStatus.approvedMaterials.avg,
                }}
              ></div>
              <div
                className=" bg-yellow-400 rounded-full h-2"
                style={{
                  width: componentsProgressStatus.waitingForReply.avg,
                }}
              ></div>
              <div
                className=" bg-gray-400 rounded-full h-2"
                style={{
                  width: componentsProgressStatus.inReviewMaterials.avg,
                }}
              ></div>
            </div>
          }
          {/* total in number */}
          <div className="flex gap-1 text-gray-400 mt-2">
            <p className="whitespace-nowrap">
              Approved: {componentsProgressStatus.approvedMaterials.total},{" "}
            </p>
            <p className="whitespace-nowrap">
              Waiting for reply:{" "}
              {componentsProgressStatus.waitingForReply.total},
            </p>
            <p className="whitespace-nowrap">
              In Review: {componentsProgressStatus.inReviewMaterials.total}
            </p>
          </div>
        </div>
      </div>

      {/* <CustomModal
        isShown={rohsPopUp}
        setModalShown={() => {
          setRohsPopUp(false);
        }}
      >
        {createRohsView()}
      </CustomModal> */}
    </div>
  );
};

export default ProductInfo;
